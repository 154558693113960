import React, { useEffect } from 'react';
import './App.css';
import ReactGA from 'react-ga4';

ReactGA.initialize('G-N9PCEGYX1G');

const App = () => {


  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search});
  }, []);

  return (
    <div className="App">
      <Navbar />
      <HeroSection />
      <Features />
      <CallToAction />
      <Footer />
    </div>
  );
}

function Navbar() {
  return (
    <nav className="navbar">
      <div className="logo">
        <h1>Trouvai</h1>
      </div>
      <ul className="nav-links">
        <li><a href="#features">Features</a></li>
        <li><a href="#about">About</a></li>
        <li><a href="#cta">Get Started</a></li>
      </ul>
    </nav>
  );
}

function HeroSection() {
  return (
    <section className="hero">
      <h1>Welcome to Trouvai</h1>
      <p>Your custom-trained AI search tool, made simple.</p>
      <MyButton buttonText='Add to Your Website'/>
    </section>
  );
}

function Features() {
  return (
    <section id="features" className="features">
      <h2>Features</h2>
      <div className="feature-list">
        <div className="feature-item">
          <h3>Customizable AI Search</h3>
          <p>Integrate a fully customizable AI tool into your website with just one line of code.</p>
        </div>
        <div className="feature-item">
          <h3>Multimedia Search</h3>
          <p>Help users discover product videos, documents, and resources effortlessly.</p>
        </div>
        <div className="feature-item">
          <h3>Personalized Recommendations</h3>
          <p>Deliver content and recommendations tailored to each user's specific needs.</p>
        </div>
      </div>
    </section>
  );
}
const MyButton = ({buttonText}) => {

  const handleClick = () => {
    // Send event to Google Analytics
    ReactGA.event({
      category: 'User',
      action: 'Click',
      label: buttonText === 'Add to Your Website' ? 'add_to_site' : 'get_started',  // Optional: can give more context
      value: 1                    // Optional: numeric value associated with the event
    });
    console.log('Clicked get started...')
    // Do other things on button click (like navigating, etc.)
  };

  return (
    <button className="cta-button" onClick={handleClick}>
      {buttonText}
    </button>
  );
};

function CallToAction() {
  return (
    <section id="cta" className="cta">
      <h2>Ready to Get Started?</h2>
      <p>Add Trouvai to your website with just one line of code. Start enhancing your website’s experience today!</p>
      <MyButton buttonText='Get Started Now'/>
    </section>
  );
}

function Footer() {
  return (
    <footer className="footer">
      <p>&copy; 2024 Trouvai. All rights reserved.</p>
      <ul className="social-links">
        <li><a href="#">Twitter</a></li>
        <li><a href="#">LinkedIn</a></li>
        <li><a href="#">GitHub</a></li>
      </ul>
    </footer>
  );
}

export default App;
